import { fabric } from "fabric";
import { Card } from "../domain/Card";
import { SchoolSettings } from "../domain/SchoolSettings";

export function getDateString(sec: number, hiragana?: boolean): string;
export function getDateString(date: Date, hiragana?: boolean): string;

export function getDateString(val: number | Date, hiragana?: boolean): string {
  const today = typeof val == "number" ? new Date(val * 1000) : val;
  const [nen, gatsu, nichi] = hiragana
    ? ["ねん", "がつ", "にち", "ようび"]
    : ["年", "月", "日"];
  const youbi = hiragana
    ? [
        "にちようび",
        "げつようび",
        "かようび",
        "すいようび",
        "もくようび",
        "きんようび",
        "どようび",
      ]
    : ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"];

  const str =
    today.getFullYear() +
    nen +
    (today.getMonth() + 1) +
    gatsu +
    today.getDate() +
    nichi +
    " " +
    youbi[today.getDay()];
  return str;
}

export const getFiscalYear = (): number => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  return month >= 4 ? year : year - 1;
};

export const getFiscalYearFromSec = (sec: number): number => {
  const today = new Date(sec * 1000); // 1624356600
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  return month >= 4 ? year : year - 1;
};

export function getImageDataUrlFromJson(json: any, canvasId: string): string {
  if (json == null) {
    return "";
  }
  if (!json?.objects) {
    return "";
  }
  if (json.objects.length == 0) {
    return "";
  }
  try {
    const canvas = new fabric.Canvas(canvasId, {
      width: 710,
      height: 405,
      allowTouchScrolling: false,
    });

    canvas.loadFromJSON(json, canvas.renderAll.bind(canvas));

    return canvas.toDataURL();
  } catch (err) {
    console.warn(err, { fabric, json });
  }
  return "";
}

export function checkBanned(
  cardId: string,
  card: Card,
  settings: SchoolSettings
): boolean {
  const allowedCCodes = new RegExp(settings.allowedCCodes);
  const bannedCCodes = new RegExp(settings.bannedCCodes);

  // Banned card
  const bannedCardId = settings.bannedCardIds?.includes(cardId);

  // Inappropriate words/publisher is banned
  const bannedPublisher =
    settings.inappropreatePublisher?.some(
      (item) => card.bookPublisher?.includes(item) && item != ""
    ) ?? false;
  const bannedTitle =
    settings.inappropriateWords?.some(
      (item) => card.bookTitle?.includes(item) && item != ""
    ) ?? false;
  const bannedCCode =
    (((settings.bannedCCodes ?? "") != "" &&
      bannedCCodes.test(card.bookCCode ?? "")) ??
      0 > 0) ||
    (((settings.allowedCCodes ?? "") != "" &&
      !allowedCCodes.test(card.bookCCode ?? "")) ??
      0 > 0);

  return bannedPublisher || bannedTitle || bannedCCode || bannedCardId;
}

export function convertBookThumbnailURL(
  bookThumbnailUrl: string | undefined,
  sec: number
): string {
  const changeOpenBDPolycyDate = new Date("2023-09-03 08:18:00");

  if (!bookThumbnailUrl) return "/noimage.png";

  // opendbを含んでいて、2023年9月3日より前の場合
  if (
    bookThumbnailUrl.match(/https:\/\/cover.openbd.jp/) &&
    new Date(sec * 1000) < changeOpenBDPolycyDate
  ) {
    return bookThumbnailUrl.replace(
      "https://cover.openbd.jp/",
      "https://storage.googleapis.com/book-card-ecceb.appspot.com/bookface/"
    );
  } else {
    return bookThumbnailUrl;
  }
}
