<template>
  <div id="card-view-option">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="main-group px-0">
        <div class="btn-group" role="group">
          <OptionButton name="filter" value="mine" icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-mine.svg#icon" />
            </svg>
          </OptionButton>

          <OptionButton
            name="filter"
            value="user"
            v-if="store.state.cardSelector.filter === 'user'"
          >
            {{ store.state.cardSelector.user.displayName }} さん
          </OptionButton>

          <OptionButton name="filter" value="myclass" v-if="store.state.user.className">
            {{ store.state.user?.className }}
          </OptionButton>

          <OptionButton
            name="filter"
            value="otherclass"
            v-if="store.state.cardSelector.schoolYearClass"
          >
            {{ store.state.cardSelector.schoolYearClass.className }}
          </OptionButton>

          <OptionButton name="filter" value="schoolyear"
            ><Hiragana f="がくねん">学年</Hiragana>
          </OptionButton>

          <OptionButton name="filter" value="school">
            {{ store.state.user?.schoolName }}
          </OptionButton>

          <OptionButton name="filter" value="otherschool"
            ><Hiragana f="たこう">他校</Hiragana></OptionButton
          >

          <OptionButton name="filter" value="all">すべて</OptionButton>
        </div>
      </div>
      <div class="main-group px-0">
        <div class="btn-group" role="group">
          <OptionButton name="sort" value="newest" icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-new.svg#icon" />
            </svg>
          </OptionButton>

          <OptionButton name="sort" value="oldest" icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-old.svg#icon" /></svg
          ></OptionButton>

          <OptionButton name="sort" value="likes" icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-like.svg#icon" /></svg
          ></OptionButton>

          <OptionButton name="sort" value="recommended" right icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-recommended.svg#icon" /></svg
          ></OptionButton>

          <!-- ２つの役割がある(同じ本の感想/感想が多い本順)-->
          <OptionButton
            name="sort"
            value="samebook"
            left
            icon
            v-if="store.state.cardSelector.sort === 'samebook'"
          >
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-book.svg#icon" /></svg
          ></OptionButton>
          <OptionButton name="sort" value="reviews" left icon v-else>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-book.svg#icon" /></svg
          ></OptionButton>

          <OptionButton name="sort" value="tags" icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-tags.svg#icon" /></svg
          ></OptionButton>

          <OptionButton name="sort" value="random" right icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-shuffle.svg#icon" /></svg
          ></OptionButton>

          <OptionButton name="sort" value="trashed" left icon>
            <svg fill="currentColor">
              <use xlink:href="/icon/icon-trash.svg#icon" /></svg
          ></OptionButton>

          <OptionButton
            name="sort"
            value="reported"
            v-if="store.state.userPreference.teacherMode"
            ><i class="bi bi-exclamation-triangle"></i>通報カード</OptionButton
          >
          <OptionButton
            name="sort"
            value="banned"
            v-if="store.state.userPreference.teacherMode"
            ><i class="bi bi-eye-slash"></i>強制非表示カード</OptionButton
          >
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-wrap">
      <!-- left collapse-->
      <div class="sub-group px-0">
        <div class="collapse mt-3" id="collapseschoolYear">
          <div class="d-flex justify-content-start">
            <div class="btn-group btn-group-sm" role="group">
              <OptionButton
                v-for="schoolYear in store.state.schoolYears"
                :key="schoolYear"
                name="schoolYear"
                :value="schoolYear"
                >{{ schoolYear }}<Hiragana f="ねんせい">年生</Hiragana></OptionButton
              >
              <OptionButton name="schoolYear" :value="0">なし</OptionButton>
            </div>
          </div>
        </div>
      </div>
      <!-- right collapse-->
      <div class="sub-group px-0">
        <!-- details of sort oldest -->
        <div class="collapse mt-3" id="collapseOldest">
          <div class="d-flex flex-column align-items-end mb-3">
            <div class="btn-group btn-group-sm" role="group">
              <OptionButton
                v-for="year in store.state.cardSelector.years"
                :key="year"
                name="oldestFiscalYear"
                :value="year"
                >{{ year }}<Hiragana f="ねんど">年度</Hiragana></OptionButton
              >
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="btn-group btn-group-sm" role="group">
              <OptionButton
                v-for="month in store.state.cardSelector.months"
                :key="month"
                name="oldestMonth"
                :value="month"
                >{{ month }}<Hiragana f="がつ">月</Hiragana></OptionButton
              >
            </div>
          </div>
        </div>

        <!-- details of sort review -->
        <div class="collapse mt-3" id="collapseReviews">
          <div class="d-flex justify-content-end">
            <div class="btn-group btn-group-sm mb-3" role="group">
              <OptionButton
                v-for="schoolYear in store.state.cardSelector.schoolYears"
                :key="schoolYear"
                name="reviewsSchoolYear"
                :value="schoolYear"
                >{{ schoolYear }}<Hiragana f="ねんせい">年生</Hiragana></OptionButton
              >
              <OptionButton name="reviewsSchoolYear" :value="0">すべて</OptionButton>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="btn-group btn-group-sm" role="group">
              <OptionButton
                v-for="year in store.state.cardSelector.years"
                :key="year"
                name="reviewsCreatedFiscalYear"
                :value="year"
                >{{ year }}<Hiragana f="ねんど">年度</Hiragana></OptionButton
              >
              <OptionButton name="reviewsCreatedFiscalYear" :value="0"
                >すべて</OptionButton
              >
            </div>
          </div>
        </div>

        <!-- details of tag -->
        <div class="collapse mt-3" id="collapseTags">
          <div class="text-end pt-3">
            <CardViewOptionTags />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted } from "vue";
import { useStore } from "@/store/store";
import OptionButton from "./CardViewOptionButton.vue";
import CardViewOptionTags from "./CardViewOptionTags.vue";
import { Collapse } from "bootstrap";
import Hiragana from "@/components/Hiragana.vue";

export default defineComponent({
  name: "CardViewOption",

  components: { OptionButton, CardViewOptionTags, Hiragana },

  setup() {
    const store = useStore();

    const unsubscribeStore = store.subscribe(() => {
      // bootstrap collapse

      //     for schoolyear
      const params = [
        {
          id: "collapseschoolYear",
          property: store.state.cardSelector.filter,
          val: "schoolyear",
        },
        {
          id: "collapseOldest",
          property: store.state.cardSelector.sort,
          val: "oldest",
        },
        {
          id: "collapseReviews",
          property: store.state.cardSelector.sort,
          val: "reviews",
        },
        {
          id: "collapseTags",
          property: store.state.cardSelector.sort,
          val: "tags",
        },
      ];
      params.forEach((item) => {
        const collapse = new Collapse(document.getElementById(item.id), {
          toggle: false,
        });
        if (item.property == item.val) {
          collapse.show();
        } else {
          collapse.hide();
        }
      });
    });

    onUnmounted(() => {
      unsubscribeStore();
    });

    return {
      store,
    };
  },
});
</script>
